
$color-text-primary-text: var(--chakra-colors-text-primary);
$color-text-secondary-text: var(--chakra-colors-text-secondary);
$color-text-highlighted-text: var(--chakra-colors-text-highlighted);
$color-text-boundaries-text: var(--chakra-colors-text-boundaries);
$color-text-tertiary-text: var(--chakra-colors-text-tertiary);
$color-text-link-text: var(--chakra-colors-text-link);

$color-main-text-on-primary: var(--chakra-colors-main-text-primary);
$color-main-text-on-highlights: var(--chakra-colors-main-text-highlights);

$color-buttons-text-on-primary-button: var(--chakra-colors-buttons-text-primary);
$color-buttons-secondary-text: var(--chakra-colors-buttons-text-secondary);
$color-buttons-success-text: var(--chakra-colors-buttons-text-success);
$color-buttons-info-text: var(--chakra-colors-buttons-text-info);
$color-buttons-warning-text: var(--chakra-colors-buttons-text-warning);
$color-buttons-danger-text: var(--chakra-colors-buttons-text-danger);
$color-buttons-help-text: var(--chakra-colors-buttons-text-help);

$color-main-primary: var(--chakra-colors-main-primary);
$color-main-main-black: var(--chakra-colors-main-black);
$color-main-tertiary: var(--chakra-colors-main-tertiary);
$color-main-error: var(--chakra-colors-main-error);
$color-main-highlights-background: var(--chakra-colors-main-background-highlights);
$color-main-hover-background: var(--chakra-colors-main-background-on-hover);
$color-main-focus-outline: var(--chakra-colors-main-on-focus-outline);

$color-surfaces-content: var(--chakra-colors-surfaces-content);
$color-surfaces-header: var(--chakra-colors-surfaces-header);
$color-surfaces-border: var(--chakra-colors-surfaces-border);
$color-surfaces-search: var(--chakra-colors-surfaces-search);
$color-surfaces-top-bar: var(--chakra-colors-surfaces-top-bar);

$color-buttons-primary-button: var(--chakra-colors-buttons-background-primary);
$color-buttons-secondary-background: var(--chakra-colors-buttons-background-secondary);
$color-buttons-success-background: var(--chakra-colors-buttons-background-success);
$color-buttons-info-background: var(--chakra-colors-buttons-background-info);
$color-buttons-warning-background: var(--chakra-colors-buttons-background-warning);
$color-buttons-danger-background: var(--chakra-colors-buttons-background-danger);
$color-buttons-help-background: var(--chakra-colors-buttons-background-help);

$color-forms-white: var(--chakra-colors-forms-background);
$color-forms-background: var(--chakra-colors-forms-background);
$color-forms-border: var(--chakra-colors-forms-border-stroke);
$color-forms-hover-border: var(--chakra-colors-forms-border-on-hover);
$color-forms-hover-background: var(--chakra-colors-forms-hover-background);

$color-statuses-succeed: var(--chakra-colors-statuses-succeed);
$color-statuses-partially-succeed: var(--chakra-colors-statuses-partially-succeed);
$color-statuses-pending: var(--chakra-colors-statuses-pending);
$color-statuses-failed: var(--chakra-colors-statuses-failed);
$color-statuses-critical: var(--chakra-colors-statuses-critical);
$color-statuses-draft: var(--chakra-colors-statuses-draft);

$color-mitre-tactic: var(--chakra-colors-mitre-tactic);
$color-mitre-technique: var(--chakra-colors-mitre-technique);
$color-mitre-sub-technique: var(--chakra-colors-mitre-subTechnique);

$color-risks-low: var(--chakra-colors-risks-low);
$color-risks-medium: var(--chakra-colors-risks-medium);
$color-risks-high: var(--chakra-colors-risks-high);
$color-risks-high2: var(--chakra-colors-risks-high2);
$color-risks-critical: var(--chakra-colors-risks-critical);
$color-risks-no-info: var(--chakra-colors-risks-no-info);
$color-risks-background-low: var(--chakra-colors-risks-background-low);
$color-risks-background-medium: var(--chakra-colors-risks-background-medium);
$color-risks-background-high: var(--chakra-colors-risks-background-high);
$color-risks-background-critical: var(--chakra-colors-risks-background-critical);
$color-risks-background-no-info: var(--chakra-colors-risks-background-no-info);

$color-messages-info: var(--chakra-colors-messages-info);
$color-messages-warning: var(--chakra-colors-messages-warning);
$color-messages-error: var(--chakra-colors-messages-error);

$color-extra-total-black: var(--chakra-colors-extra-total-black);
$color-extra-purple-content: var(--chakra-colors-extra-purple-content);
$color-extra-light-purple-content: var(--chakra-colors-extra-light-purple-content);
$color-extra-disabled: var(--chakra-colors-extra-disabled);
$color-extra-high-background: var(--chakra-colors-extra-background-high);
$color-extra-critical-background: var(--chakra-colors-extra-background-critical);
$color-extra-high-hover: var(--chakra-colors-extra-high-on-hover);
$color-extra-success-hover: var(--chakra-colors-extra-success-on-hover);
$color-extra-warning-hover: var(--chakra-colors-extra-warning-on-hover);
$color-extra-hover-on-secondary: var(--chakra-colors-extra-secondary-on-hover);
$color-extra-title-side-panel: var(--chakra-colors-extra-title-side-panel);
$color-extra-side-panel: var(--chakra-colors-extra-side-panel);
$color-extra-side-panel-second: var(--chakra-colors-extra-side-panel-second);
$color-extra-menu-item-selected: var(--chakra-colors-extra-menu-item-selected);
$color-extended-menu-panel-first: var(--chakra-colors-extra-extended-menu-panel-first);
$color-extended-menu-panel-second: var(--chakra-colors-extra-extended-menu-panel-second);
$color-selected-menu-icon: var(--chakra-colors-extra-selected-menu-icon);
$color-menu-item-text: var(--chakra-colors-extra-menu-item-text);
$color-menu-item-text-selected: var(--chakra-colors-extra-menu-item-text-selected);
$color-menu-panel-border: var(--chakra-colors-extra-menu-panel-border);
$color-extra-header-expand-panel: var(--chakra-colors-extra-header-expand-panel);

$color-general-0: #181d1f;
$color-general-3: #181d1f;
$color-general-4: #767676;
$color-general-6: #cbcbcb;
$color-general-7: #e8e8e8;
$color-general-8: #f3f3f3;
$color-general-9: #ffffff;
$color-general-12: #f9f9f9;
$color-general-21: #d9f2e8;
$color-general-22: #fff6e1;

$color-1: #64acfa;
$color-1-dark: #498dd6;
$color-2: #019f5c;
$color-3: #ffbe2f;
$color-5: #e31c3d;
$color-6: #48206e;
$color-6-light: #9586bc;
$color-7: #6a040f;
$color-7-light: #e1ced0;
$color-8: #9e4c55;
$color-9: #cbcbcb;
$color-10: #e31c3d;
$color-25: #fbdce1;
$color-27: #f3f3f3;
$color-29: #e5f2ff;
$color-30: #717171;

$color-white: #ffffff;

$color-dark: #3c3c3c;
$color-warning: #faca62;
$color-1-medium: #6FB5FB;
$color-1-light: #ddecfb;
$color-1-extra-light: #f0f6fd;
$color-2-dark: #85b129;
$color-2-light: #e0ebcb;
$color-3-light: #fae7bd;
$color-3-extra-light: #FEF9Ef;
$color-4: #ffa22f;
$color-4-light: #facd96;
$color-5-dark: #c1272d;
$color-5-light: #ff9074;
$color-5-extra-light: #ffcec2;
$color-6-dark: #372564;
$color-6-variant: #2E0951;
$color-6-extra-light: #f3efff;
$color-11: #707070;
$color-12: #64ABFA;
$color-13: #E0E0E0;
$color-14: #E9E9E9;
$color-15: #3C1B8B;
$color-16: #3D1C8C;
$color-17: #6B1C76;
$color-18: #8A2262;
$color-19: #41962A;
$color-20: #7FC83D;
$color-21: #F9CD46;
$color-22: #F39D39;
$color-23: #EF6F2E;
$color-24: #EC3323;
$color-26: #DADADA;
$color-28: #2684FF;
$color-general-1: #101010;
$color-general-2: #2b2b2b;
$color-general-5: #aeaeae;
$color-general-10: #f7f7f7;
$color-general-11: #f4f4f4;
$color-general-13: #f23535;
$color-general-14: #E5F5EE;
$color-general-15: #979797;
$color-general-16: #64ACFB;
$color-general-17: #FFB70E;
$color-general-18: #009F5C;
$color-general-19: #E4E2EA;
$color-general-20: #F5DBDD;
$color-general-23: #FEF9EF;