@import "../../packages/uikit/public/typography.scss";

@mixin font-bold {
  font-family: var(--chakra-fonts-bold);
}

@mixin font-regular {
  font-family: "Proxima Nova W08 Reg";
}

@mixin font-thin {
  font-family: "Proxima Nova W08 Thn Reg";
}

@mixin one-line-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin revoke-builtin-button-style {
  padding: 0;
  background: none;
  outline: none;
  box-shadow: 0 0 0 transparent;
  border: 0 solid transparent;
  text-shadow: 0 0 0 transparent;
}

@mixin spinner($color:$color-main-text-on-primary, $size:40px, $display: 'block') {
  display: $display;

  .circle,
  .circle:after {
    border-radius: 50%;
    width: $size;
    height: $size;
  }
  .circle {
    display: $display;
    margin: 0 auto;
    font-size: calc($size / 3);
    border: calc($size / 5) solid rgba(black, .2);
    border-left-color: $color;
    transform: translateZ(0);
    animation: load-anim 1.1s infinite linear;
  }
  @keyframes load-anim {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

@mixin popup-button-base {
  border-radius: 30px;
  font-size: 16px;
  user-select: none;
  outline: none;
  cursor: pointer;

  > a {
    text-decoration: none;
  }
}

/*Typography mixins*/

@mixin base-regular {
  font-family: Proxima Nova W08 Reg;
  font-size: 13px;
  line-height: 16px;
}

@mixin base-semibold {
  font-family: Proxima Nova W08 Smbd;
  font-size: 13px;
  line-height: 16px;
}

@mixin b1-regular {
  font-family: Proxima Nova W08 Reg;
  font-size: 16px;
  line-height: 24px;
}

@mixin b1-semibold {
  font-family: Proxima Nova W08 Smbd;
  font-size: 16px;
  line-height: 24px;
}

@mixin h6-regular {
  font-family: Proxima Nova W08 Reg;
  font-size: 13px;
  line-height: 18px;
}

@mixin h6-bold {
  font-family: Proxima Nova Bld;
  font-size: 13px;
  line-height: 18px;
}

@mixin h5-regular {
  font-family: Proxima Nova W08 Reg;
  font-size: 16px;
  line-height: 20px;
}

@mixin h5-semibold {
  font-family: Proxima Nova W08 Smbd;
  font-size: 16px;
  line-height: 20px;
}

@mixin h5-bold {
  font-family: Proxima Nova Bld;
  font-size: 16px;
  line-height: 20px;
}

@mixin h4-regular {
  font-family: Proxima Nova W08 Reg;
  font-size: 20px;
  line-height: 24px;
}

@mixin h4-semibold {
  font-family: Proxima Nova W08 Smbd;
  font-size: 20px;
  line-height: 24px;
}

@mixin h4-bold {
  font-family: Proxima Nova Bld;
  font-size: 20px;
  line-height: 24px;
}

@mixin h3-bold {
  font-family: Proxima Nova Bld;
  font-size: 25px;
  line-height: 28px;
}

@mixin h2-bold {
  font-family: Proxima Nova Bld;
  font-size: 30px;
  line-height: 38px;
}

@mixin h1-bold {
  font-family: Proxima Nova Bld;
  font-size: 34px;
  line-height: 40px;
}


