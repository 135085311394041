$deck-width: 750px;
$deck-items-margin: 46;
$deck-items-standard-width: 490;
$top-bar-height: 25px;
$top-bar-padding-top: 10px;
$top-bar-padding-bottom: 10px;

$z-index-input-overlay: 1;
$z-index-charts-overlay: 1;
$z-index-card-overlay: 2;
// $z-index-side-panel: 9998; defined in armis/common/js/elements/css/globals.scss
$z-index-top-bar: $z-index-side-panel;
$z-index-notification: $z-index-side-panel + 1;
$z-index-left-bar: $z-index-side-panel + 2;
$z-index-centrix-header: $z-index-side-panel + 15;
$z-index-dropdown: $z-index-side-panel + 4;
$z-index-skip-content-link: $z-index-side-panel + 6;
$z-index-popup: $z-index-side-panel + 14;
$z-index-loader: $z-index-side-panel + 15;
// $z-index-tooltip: 8; defined in armis/common/js/elements/css/globals.scss

:root {
  --z-index-centrix-header: #{$z-index-centrix-header};
}