@import '../../src/baseUI/framework/framework';

@mixin login-text($size: 12px, $color: $color-6) {
  @include font-thin();
  font-size: $size;
  color: $color;
  letter-spacing: 0.4px;
}

body {
  @include font-regular();
  margin: 0;
  height: 100vh;
  padding: 0;
}

.login-right-logo {
  width: 450px;
  height: 400px;
  @media (max-width: 1440px) {
    width: 350px;
    height: 300px;
  }
}

.login-container {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin-top: 100px;
  @media (max-width: 1440px) {
    margin-top: 70px;
  }

  .login-header {
    font-size: 40px;
    color: white;
    margin-bottom: 30px;
    margin-top: 15px;
    line-height: 40px;
    @media (max-width: 1440px) {
      font-size: 30px;
      line-height: 30px;
    }
  }

  .login-sub-header {
    font-size: 20px;
    color: white;
    margin-bottom: 30px;
    @media (max-width: 1440px) {
      font-size: 15px;
    }
  }
}

.loader-outer {
  margin-top: 20px;
  .loader > span {
    width: 15px;
    height: 15px;
    display: block;
    background: #fff;
    border-radius: 50%;
    position: relative;
    margin: 0 5px;
  }

  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dot-1 {
    animation: anim 1s linear 0s infinite;
  }

  .dot-2 {
    animation: anim 1s linear 0.25s infinite;
  }

  .dot-3 {
    animation: anim 1s linear 0.5s infinite;
  }

  .dot-4 {
    animation: anim 1s linear 0.75s infinite;
  }

  @keyframes anim {
    0% {
      top: 0;
    }

    50% {
      top: 15px;
    }

    100% {
      top: 0;
    }
  }
}

.login-video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.captcha {
  display: flex;
  justify-content: center;
  padding: 10px 0;
}

.container {
  display: flex;
  height: 100vh;
  //align-items: center;
  //justify-content: flex-start;
  margin-left: 180px;
}

.form {
  display: flex;
  $input-border: 1px solid $color-forms-hover-border;

  > .image-container {
    display: flex;
    flex: 1;
    justify-content: center;
    padding-right: 30px;

    width: 260px;
    border-right: 1px solid $color-extra-hover-on-secondary;

    a {
      display: flex;
      justify-content: center;

      &:focus {
        outline: none;
      }
    }
  }

  > .content {
    width: 300px;
    //background-color: #fafafc;
    //border-radius: 4px;
    //padding: 30px 40px 20px 40px;
    padding-top: 30px;

    > .header {
      @include font-bold();
      color: white;
      font-size: 16px;
      letter-spacing: 0.4px;
      //margin-bottom: 20px;
    }

    > .tos {
      margin-top: 50px;

      > .text {
        @include login-text($color: $color-general-5);

        a {
          @include login-text();
          text-decoration: none;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    > .input-field {
      margin-bottom: 20px;
      //margin-top: 30px;

      > .label {
        @include login-text();
        transition: opacity 0.1s linear;
        color: white;

        &.hidden {
          opacity: 0;
        }
      }

      > .input,
      > .pass > .input {
        @include login-text(16px, $color-general-3);
        flex: 1;
        padding: 5px 0;
        margin: 0;
        box-sizing: border-box;
        width: 100%;
        outline: none;
        //border-bottom: $input-border;
        background: transparent;
        border: 1px solid white;
        padding: 10px;
        color: white;

        &:focus {
          //border-bottom-color: $color-6;
        }

        &::placeholder {
          color: $color-general-5;
        }

        &.error {
          border-color: $color-5;
        }
      }

      > .pass {
        display: flex;
        flex-direction: column;
        width: 100%;

        > .link {
          @include login-text(11px);
          color: white;
          text-decoration: none;
          cursor: pointer;
          margin-top: 10px;
          padding-left: 5px;
          border-bottom: $input-border;

          &.error {
            border-color: $color-5;
          }
        }
      }
    }

    > .eula-agreement {
      display: flex;
      margin-bottom: 20px;

      > .eula-agreement-checkbox {
        margin: 5px 10px 0px 0px;
      }

      > .eula-agreement-text {
        font-size: 14px;
        color: white;

        > a {
          margin-right: 5px;
          color: white;
        }
      }
    }

    > .footer {
      display: flex;
      align-items: baseline;
      flex-direction: column;
      margin-top: 10px;

      > .button {
        @include font-bold();
        font-size: 14px;
        color: $color-general-8;
        border: none;
        border-radius: 4px;
        padding: 10px 20px;
        min-width: 110px;
        cursor: pointer;
        outline: none;
        background-color: #2abaff;
        width: 100%;

        &:hover {
          //background-color: $color-6-dark;
        }
      }

      > .error,
      .message {
        @include font-thin();
        flex: 2;
        font-size: 12px;
        padding-left: 20px;
        align-self: center;
        text-align: end;
      }

      > .error {
        color: white;
      }

      > .message {
        color: white;
      }
    }
  }
}

.popup {
  @include absolute-full-size();
  width: 100%;
  height: 100%;
  background-color: rgba($color-general-2, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: $z-index-popup;
  outline: none;

  > .popup-content {
    outline: none;
    width: 500px;
    height: 300px;
    background-color: $color-general-9;
    border-radius: 4px;
    padding: 30px;

    > .text {
      height: 240px;
      overflow: auto;
      word-break: break-word;
      white-space: pre-wrap;
    }

    > .button {
      width: 170px;
      cursor: pointer;
      background-color: $color-1;
      color: $color-general-9;
      border-radius: 4px;
      margin-right: auto;
      margin-left: auto;
      text-align: center;
      padding: 8px;
    }
  }
}

.login-logo {
  margin-bottom: 100px;
}
