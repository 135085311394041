$max: 50;
$offset: 2;
$unit: 'px';

@mixin list-loop($className, $styleName) {
  $i: 0;
  @while $i <= $max {
    #{$className + $i} {
      #{$styleName}: #{$i + $unit};
    }
    $i: $i + $offset;
  }
}

// Margins
@include list-loop('.m-t-', 'margin-top');
@include list-loop('.m-r-', 'margin-right');
@include list-loop('.m-b-', 'margin-bottom');
@include list-loop('.m-l-', 'margin-left');
@include list-loop('.m-', 'margin');

// Paddings
@include list-loop('.p-t-', 'padding-top');
@include list-loop('.p-r-', 'padding-right');
@include list-loop('.p-b-', 'padding-bottom');
@include list-loop('.p-l-', 'padding-left');
@include list-loop('.p-', 'padding');
