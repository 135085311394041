@import 'globals';

@mixin clip-path($clip) {
  -webkit-clip-path: $clip;
  -moz-clip-path: $clip;
  -ms-clip-path: $clip;
  clip-path: $clip;
}

@mixin flex1 {
  flex: 1;
}

@mixin textSize($size) {
  font-size: $size;
  line-height: $size;
}

@function width($columns) {
  @return $columns * $deck-items-standard-width + ($columns - 1) *
    $deck-items-margin;
}

@mixin make-responsive {
  $margin: 64px;
  width: calc(100% - #{$margin * 2});
  min-width: width(2) + px;
  max-width: width(3) + px;
  margin-left: auto;
  margin-right: auto;
}

@mixin absolute($top: 0, $right: 0, $bottom: 0, $left: 0) {
  position: absolute;
  top: $top;
  bottom: $bottom;
  left: $left;
  right: $right;
}

@mixin absolute-full-size($top: 0) {
  @include absolute($top, 0, 0, 0);
}

@mixin absolute-top-left($top-offset: 0, $left-offset: 0) {
  @include absolute($top-offset, null, null, $left-offset);
}

@mixin absolute-top-full-width($top-offset: 0) {
  @include absolute($top-offset, 0, null, 0);
}

@mixin absolute-center-horizontal() {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin disabled {
  cursor: auto;
  opacity: 0.5;
  pointer-events: none;
}

@mixin link(
  $font-size: var(--chakra-fontSizes-sm),
  $color: $color-text-link-text,
  $active-color: $color-main-highlights-background
) {
  @include font-bold();
  font-size: $font-size;
  color: $color;
  line-height: var(--chakra-lineHeights-lh1);
  text-decoration: none;
  cursor: pointer;

  &:hover:not(.disabled) {
    text-decoration: none;
  }

  &:active:not(.disabled) {
    text-decoration: none;
  }

  &.disabled {
    cursor: default;
    color: $color;
    opacity: 0.5;
  }
}

@mixin filter-header(
  $change-on-hover: false,
  $color: $color-general-4,
  $border-style: solid,
  $background-color: $color-forms-background,
  $padding: 8px 12px
) {
  padding-left: 4px;
  font-size: 13px;
  line-height: 18px;
  padding: $padding;
  color: $color;
  border: 1px $border-style $color;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: $background-color;
  transition: background-color 0.25s ease, color 0.25s ease,
    border-color 0.25s ease;

  > .arrow {
    fill: $color-text-primary-text;
    transition: fill 0.5s ease;
  }

  @if $change-on-hover {
    &:hover {
      background-color: $color-main-hover-background;
      color: $color-main-text-on-primary;
      border-color: $color-surfaces-border;

      > .arrow {
        fill: $color-text-primary-text;
      }
    }
  }
}

@mixin loader-bg($bg-color: $color-dark) {
  @include absolute-full-size();
  display: flex;
  background-color: $bg-color;
  align-items: center;
  transition: background-color 0.5s linear;

  &.fade {
    background-color: rgba($bg-color, 0.1);
    backdrop-filter: blur(4px);
  }

  &.hide {
    display: none;
  }
}

@mixin dots-loader(
  $dots-color: $color-text-secondary-text,
  $bg-color: $color-dark,
  $size: 15px
) {
  @if ($bg-color) {
    @include loader-bg($bg-color);
  }
  z-index: $z-index-loader;

  .dots:before,
  .dots:after,
  .dots {
    border-radius: 50%;
    width: $size;
    height: $size;
    animation-fill-mode: both;
    animation: size 1.8s infinite ease-in-out;
  }

  .dots {
    color: rgba($dots-color, 0.75);
    font-size: 10px;
    margin: 0 auto;
    position: relative;
    text-indent: -9999em;
    animation-delay: -0.16s;
    transform: translate(0, -100%);

    &:before {
      left: -3.5em;
      animation-delay: -0.32s;
    }

    &:after {
      left: 3.5em;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
    }
  }

  @keyframes size {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
}

@mixin circle-loader() {
  @include loader-bg();
  @include spinner($color: $color-text-primary-text);
}

@mixin double-border-inset-shadow($circle-clear-border-size: 20px) {
  border-radius: 50%;
  border: none;
  position: relative;
  margin: $circle-clear-border-size;

  &:before {
    content: '';
    background-color: $color-forms-white;
    border: 1px solid $color-forms-border;
    border-radius: 50%;
    left: -$circle-clear-border-size;
    top: -$circle-clear-border-size;
    bottom: -$circle-clear-border-size;
    right: -$circle-clear-border-size;
    position: absolute;
  }

  &:after {
    border-radius: 50%;
    box-shadow: inset 0 0 15px $color-general-4;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    content: '';
    pointer-events: none;
  }
}

@mixin focus() {
  &:focus {
    outline-color: $color-main-primary;
  }
}

@mixin item-label() {
  background-color: $color-main-primary;
  padding: 0 10px;

  color: $color-general-11;
  font-size: 12px;

  user-select: none;
  cursor: default;
}

@mixin line_process($end) {
  animation: flow 2s infinite linear;

  @keyframes flow {
    0%,
    100% {
      left: 0;
    }
    25%,
    75% {
      left: $end / 2;
    }
    50% {
      left: $end;
    }
  }
}
